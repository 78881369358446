import axios from "axios";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import config from "../config";
import User from "../helpers/login/userData";
/**
 *
 * @Components props.data
 */

const StatementCard = () => {
  const [statements, setStatements] = useState([]);
  const [message, setMessage] = useState("");
  const [filter, setFilter] = useState();
  const user = User();
  const getStatements = (data) => {
    if (config.GET_STATEMENTS_API)
      axios
        .get(config.GET_STATEMENTS_API, {
          params: data,
        })
        .then((res) => {
          if (res.data) {
            setStatements(res?.data?.statements);
          } else {
            setMessage(res?.data?.message);
          }
        })
        .catch((err) => {
          setMessage("There are currently no statements to display");
        });
  };
  useEffect(() => {
    getStatements({
      userid: user.userid,
      email: user.email,
      timeframe: filter,
    });
  }, [filter]);

  const sendEmail = () => {
    const data = {
      sendemail: "1",
      email: user?.email,
      statements: statements.flatMap((s) => s.statementid).toString(),
    };

    //const url =
    // config.GET_STATEMENTS_API + "?email=" + user?.email + "&&sendemail=1";
    if (config.GET_STATEMENTS_API)
      axios
        .get(config.GET_STATEMENTS_API, {
          params: data,
        })
        .then((res) => {
          if (res.data?.success == "true") {
            toast.success("Email sent Successfully", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else {
            toast.error(res?.data?.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch((err) => {
          setMessage("There are currently no statements to display");
        });
  };
  return (
    <div className="card statement-card">
      <div className="statement-title">
        <span className="title">Statements</span>
        {statements?.length > 0 && (
          <select
            aria-label="Default select example"
            onChange={(e) => {
              setFilter(e.target.value);
            }}
            value={filter}
          >
            <option value="" selected>
              Select the Latest
            </option>
            <option value="1">Last 1</option>
            <option value="3">Last 3 </option>
            <option value="6">Last 6 </option>
            <option value="12">Last 12 </option>
          </select>
        )}
      </div>
      <div className="mx-3">
        {statements?.map(({ date, name, fileurl }, idx) => (
          <div className="py-2 border-top row" key={idx}>
            <div className="col-md-3 col-5 font-weight-bold">{date}</div>
            <div className="col-md-8 col-6">{name}</div>
            <a
              className="col-md-1 col-1 btn disabled border-0"
              href={fileurl}
              target="_blank"
            >
              <i className="fa-solid fa-download"></i>
            </a>
          </div>
        ))}
        {statements?.length > 0 && filter != 0 && (
          <Button className="send-email-button" onClick={sendEmail}>
            Send Email
          </Button>
        )}
        {message && <div className="my-3">{message}</div>}
      </div>
    </div>
  );
};
export default StatementCard;
