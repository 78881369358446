import axios from "axios";
import config from "../config";
import { accountActions } from "./my-account-slice";
export const getAccountData = (username) => {
  return (dispatch) => {
    axios
      .get(config.myAccountApi)
      .then((res) => {
        const data = { ...res.data };
        data.username = username;
        dispatch(accountActions.replaceAcountInfo(data));
      })
      .catch((err) => console.log("Error"));
  };
};

export const replaceAcountData = (userInfo) => {
  return (dispatch) => {
    dispatch(accountActions.replaceAcountInfo(userInfo));
  };
};
export const loginUser = (userInfo) => {
  return (dispatch) => {
    dispatch(accountActions.login());
    dispatch(accountActions.replaceAcountInfo(userInfo));
  };
};
export const logout = () => {
  return (dispatch) => {
    dispatch(accountActions.logout());
  };
};
