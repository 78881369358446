import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
/** 
 * @input json : 
 * "carouselSlick": {
          "title": "We are your complete solution",
          "subTitle": " it's not just about finding a reliable vehicle but also finding one that suits your unique lifestyle",
          "slides": [
           
              "id": "item-14",
              "name": "2019 Land Rover Discovery Sport HSE Luxury AWD",
              "price": 22220,
              "isSale": true,
              "salePrice": 22000,
              "description": "<div class=\"g8xmKr\" style=\"--columns: 2;\"><div class=\"hP30qs\"><div class=\"JtWUya\"><span class=\"om75fw\" data-cg-ft=\"make\">Make:</span><span class=\"BivK6Q\">Land Rover</span></div><div class=\"JtWUya\"><span class=\"om75fw\" data-cg-ft=\"model\">Model:</span><span class=\"BivK6Q\">Discovery Sport</span></div><div class=\"JtWUya\"><span class=\"om75fw\" data-cg-ft=\"year\">Year:</span><span class=\"BivK6Q\">2019</span></div><div class=\"JtWUya\"><span class=\"om75fw\" data-cg-ft=\"trim\">Trim:</span><span class=\"BivK6Q\">HSE Luxury AWD</span></div><div class=\"JtWUya\"><span class=\"om75fw\" data-cg-ft=\"bodyType\">Body type:</span><span class=\"BivK6Q\">SUV / Crossover</span></div><div class=\"JtWUya\"><span class=\"om75fw\" data-cg-ft=\"exteriorColor\">Exterior color:</span><span class=\"BivK6Q\">White</span></div></div><div class=\"hP30qs\"><div class=\"JtWUya\"><span class=\"om75fw\" data-cg-ft=\"interiorColor\">Interior color:</span><span class=\"BivK6Q\">Black</span></div><div class=\"JtWUya\"><span class=\"om75fw\" data-cg-ft=\"mileage\">Mileage:</span><span class=\"BivK6Q\">52,658 mi</span></div><div class=\"JtWUya\"><span class=\"om75fw\" data-cg-ft=\"condition\">Condition:</span><span class=\"BivK6Q\">Used</span></div><div class=\"JtWUya\"><span class=\"om75fw\" data-cg-ft=\"vin\">VIN:</span><span class=\"BivK6Q\">SALCT2FX0KH788604</span></div><div class=\"JtWUya\"><span class=\"om75fw\" data-cg-ft=\"stockNumber\">Stock number:</span><span class=\"BivK6Q\">KH788604</span></div></div></div>",
              "img": "https://static.cargurus.com/images/forsale/2024/04/20/15/48/2019_land_rover_discovery_sport-pic-38518277586600064-1024x768.jpeg"
            },
             
              "id": "item-15",
              "name": "2019 Land Rover Discovery Sport HSE Luxury AWD",
              "price": 22220,
              "isSale": true,
              "salePrice": 22000,
              "description": "<div class=\"g8xmKr\" style=\"--columns: 2;\"><div class=\"hP30qs\"><div class=\"JtWUya\"><span class=\"om75fw\" data-cg-ft=\"make\">Make:</span><span class=\"BivK6Q\">Land Rover</span></div><div class=\"JtWUya\"><span class=\"om75fw\" data-cg-ft=\"model\">Model:</span><span class=\"BivK6Q\">Discovery Sport</span></div><div class=\"JtWUya\"><span class=\"om75fw\" data-cg-ft=\"year\">Year:</span><span class=\"BivK6Q\">2019</span></div><div class=\"JtWUya\"><span class=\"om75fw\" data-cg-ft=\"trim\">Trim:</span><span class=\"BivK6Q\">HSE Luxury AWD</span></div><div class=\"JtWUya\"><span class=\"om75fw\" data-cg-ft=\"bodyType\">Body type:</span><span class=\"BivK6Q\">SUV / Crossover</span></div><div class=\"JtWUya\"><span class=\"om75fw\" data-cg-ft=\"exteriorColor\">Exterior color:</span><span class=\"BivK6Q\">White</span></div></div><div class=\"hP30qs\"><div class=\"JtWUya\"><span class=\"om75fw\" data-cg-ft=\"interiorColor\">Interior color:</span><span class=\"BivK6Q\">Black</span></div><div class=\"JtWUya\"><span class=\"om75fw\" data-cg-ft=\"mileage\">Mileage:</span><span class=\"BivK6Q\">52,658 mi</span></div><div class=\"JtWUya\"><span class=\"om75fw\" data-cg-ft=\"condition\">Condition:</span><span class=\"BivK6Q\">Used</span></div><div class=\"JtWUya\"><span class=\"om75fw\" data-cg-ft=\"vin\">VIN:</span><span class=\"BivK6Q\">SALCT2FX0KH788604</span></div><div class=\"JtWUya\"><span class=\"om75fw\" data-cg-ft=\"stockNumber\">Stock number:</span><span class=\"BivK6Q\">KH788604</span></div></div></div>",
              "img": "https://static.cargurus.com/images/forsale/2024/04/20/15/48/2019_land_rover_discovery_sport-pic-38518277586600064-1024x768.jpeg"
            },
          ],
          "mode_comment": "pulledUp|normal|halfWide"
        }
*/
const Carousel = (props) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const { title, subTitle, slides, speed, dots, slidesToShow, className } =
    props?.data;

  const settings = {
    dots: dots ? true : false,
    infinite: true,
    autoplay: true,
    speed: speed,
    slidesToShow: slidesToShow ? slidesToShow : 1,
    slidesToScroll: 1,
    vertical: false,
    verticalSwiping: true,
    beforeChange: function (currentSlide, nextSlide) {
      //console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      //console.log("after change", currentSlide);
    },
  };

  return (
    <div
      className={
        className ? className + " slider-container" : " slider-container"
      }
    >
      <h2 className="title d-flex justify-content-center py-3">{title}</h2>
      <Slider {...settings}>
        {slides?.map((item, i) => {
          return (
            <div
              key={i}
              className="slider-item d-flex flex-row  p-2"
              style={{
                backgroundImage: `url("${item.img}")`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "100%",
                backgroundPositionY: "100%",
              }}
            >
              {item.img && (
                <img
                  src={item.img}
                  alt={item.title}
                  width="50%"
                  className="col-6"
                />
              )}

              {item.description && (
                <div className="desc col-6 rounded">
                  <div
                    className=" p-4 rounded"
                    dangerouslySetInnerHTML={{
                      __html: item.description,
                    }}
                  ></div>
                  {item.price && (
                    <h2 className="p-2 m-2 rounded bg-secondary text-white">
                      ${item.price}
                    </h2>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default Carousel;
