import { Fragment } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Auth from "../../helpers/login/auth";
/**
 *  @Component props.data:
 * img
 * imgClass
 * title
 * content
 * buttonText
 * buttonLink
 * border
 *
 */
const Navigation = (props) => {
  const auth = Auth();
  const location = useLocation();
  const getClassName = (pos) => {
    if (pos === "right" || pos === "left") return "navigation-col";
    else if (pos === "top" || pos === "bottom") return "navigation-row";
    else return "";
  };
  const navigate = useNavigate();
  const gotoURL = (url) => {
    navigate(url);
  };
  const getNav = (navData, idx) => {
    const nav = (
      <Nav
        className={`${
          navData?.className ? navData.className : ""
        } ${getClassName(navData?.position)}`}
        as="ul"
      >
        {navData?.items &&
          navData?.items?.map((item, index) => (
            <Fragment key={index}>
              {item.sublist && (
                <NavDropdown
                  title={item?.name}
                  className="my-1"
                  id="basic-nav-dropdown"
                  as="ul"
                >
                  {item?.sublist?.map(
                    (listitem, idx) =>
                      (!listitem.restricted ||
                        (listitem?.restricted && auth)) && (
                        <NavDropdown.Item
                          key={idx}
                          as="li"
                          onClick={() => gotoURL(listitem?.url)}
                        >
                          {listitem?.name && (
                            <span
                              className={`${
                                location?.pathname?.includes(
                                  listitem?.url + "/"
                                ) || location?.pathname === listitem?.url
                                  ? "active"
                                  : ""
                              }`}
                            >
                              {listitem.name}
                            </span>
                          )}
                        </NavDropdown.Item>
                      )
                  )}
                </NavDropdown>
              )}
              {!item.sublist && (
                <Nav.Item className="my-1" as="li">
                  {(!item.restricted || (item?.restricted && auth)) && (
                    <a
                      href={item?.url ? item.url : "/"}
                      className="px-2 py-0"
                      onClick={() => {
                        window.analytics.track("clicked navigation link ", {
                          title: item.name,
                        });
                      }}
                    >
                      {item?.name && (
                        <span
                          className={`${
                            location?.pathname?.includes(item?.url + "/") ||
                            location?.pathname === item?.url
                              ? "active"
                              : ""
                          }`}
                        >
                          {item.name}
                        </span>
                      )}
                    </a>
                  )}
                </Nav.Item>
              )}
            </Fragment>
          ))}
      </Nav>
    );
    if (navData?.type && navData?.type === "collapse")
      return (
        <>
          <Navbar.Toggle
            className="col-md-1 offset-md-5 col-2 offset-4"
            aria-controls={`basic-navbar-nav-${idx}`}
          />
          <Navbar.Collapse
            className="col-lg-6 col-md-5"
            id={`basic-navbar-nav-${idx}`}
          >
            {nav}
          </Navbar.Collapse>
        </>
      );
    // else if (navData.type && navData.type === "offcanvas")
    //   return (
    //     <Navbar expand={false} className="mb-3">
    //       <Navbar.Toggle aria-controls="offcanvasNavbar-expand-false" />
    //       <Navbar.Offcanvas
    //         id="offcanvasNavbar-expand-false"
    //         aria-labelledby={`offcanvasNavbarLabel-expand-false`}
    //         placement="end"
    //       >
    //         <Offcanvas.Header closeButton>Nav</Offcanvas.Header>
    //         <Offcanvas.Body>{nav}</Offcanvas.Body>
    //       </Navbar.Offcanvas>
    //     </Navbar>
    //   );
    else return nav;
  };
  return (
    <>
      {props?.data &&
        props?.data?.map((navData, idx) => {
          return <Fragment key={idx}>{getNav(navData, idx)}</Fragment>;
        })}
    </>
  );
};

export default Navigation;
