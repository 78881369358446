/**
 *
 * @Container props.data
 * className: css classes for parent container
 * data{
 * img,
 * title,
 * blurb,
 * buttonText,
 * buttonLink }
 */

import { Link } from "react-router-dom";
const parse = require("html-react-parser");

const Banner = (props) => {
  const {
    img,
    imgClass,
    title,
    blurb,
    buttonText,
    buttonLink,
    textClass,
    className,
  } = props.data;
  return (
    <section
      style={{
        background: `url(${img}) center center / cover`,
        // backgroundSize: `cover`,
        // backgroundPosition: `center`,
      }}
      className={`banner ${imgClass ? imgClass : ""} ${
        className ? className : ""
      }`}
    >
      <div className={`banner-content ${textClass ? textClass : ""}`}>
        {title && <h3>{title}</h3>}
        {blurb && <span className="banner-blurb">{parse(blurb)}</span>}
        {buttonText && (
          <a
            className="btn btn-primary btn-lg mb-4 banner-button"
            href={buttonLink}
            role="button"
            onClick={() => {
              window.analytics.track("clicked Banner button " + buttonText, {
                title: "Banner Button",
              });
            }}
          >
            {buttonText}
          </a>
        )}
      </div>
    </section>
  );
};

export default Banner;
