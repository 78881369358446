import axios from "axios";
import { useEffect, useState } from "react";
import AccountCard from "../components/AccountCard";
import ActivityCard from "../components/ActivityCard";
import Banner from "../components/Common/Banner";
import ContactDetail from "../components/Common/ContactDetails";
import Links from "../components/Common/linksContainer";
import LoginForm from "../components/Common/loginForm";
import MortgageCalc from "../components/MortgageCalc";
import NetoCalculator from "../components/NetoCalculator";
import ThreeColCards from "../components/Retired/ThreeColCards";
import FourColCards from "../components/Common/FourColCards";
import Config from "../config";
import CardContainer from "../components/Common/CardContainer";
import CardContainerBanner from "../components/Common/CardContainerBanner";
import ApplicationProcess from "../components/ApplicationProcess";
import SingleCard from "../components/Common/card.single";
import FormContainer from "../components/Common/formContainer";
import Auth from "../helpers/login/auth";
import Navigation from "../components/Common/navigation";
import ProductDetails from "../components/pages/ProductDetail";
import { getProducts } from "../store/products-actions";
import { useDispatch } from "react-redux";
import SimpleModal from "../components/Common/modal";
import Cart from "../components/Common/Cart";
import CheckOut from "../components/Common/Checkout";
import ListContainer from "../components/Common/listContainer";
import PhotoGallery from "../components/Common/PhotoGallery";
import { getBlogs } from "../store/blogs-actions";
import { loginUser } from "../store/my-account-actions";
import BlogArticle from "../components/Common/BlogArticle";
import Wishlist from "../components/Wishlist";
import ContentComponent from "../components/Common/ContentComponent";
import { getpromotions } from "../store/promotions-actions";
import PromotionList from "../components/PromotionList";
import ImagePromo from "../components/Common/ImagePromo";
import Carousel from "../components/Common/Carousel";
import UserDetail from "../components/Common/UserDetail";
import MyOrder from "../components/Common/MyOrder";
import StatementCard from "../components/StatementCard";
import SearchProducts from "../components/SearchProducts";
import CarouselSlick from "../components/Common/CarouselSlick";
import Scheduler from "../components/Scheduler";

import { Helmet } from "react-helmet";

const componentsMap = {
  banner: Banner,
  contactDetails: ContactDetail,
  cards: ThreeColCards,
  card: SingleCard,
  headerCard: SingleCard,
  links: Links,
  fourcolcards: FourColCards,
  accountCard: AccountCard,
  activityCard: ActivityCard,
  mortgageCalculator: MortgageCalc,
  netoCalculator: NetoCalculator,
  cardContainer: CardContainer,
  cardContainerBanner: CardContainerBanner,
  applicationProcess: ApplicationProcess,
  formContainer: FormContainer,
  navigations: Navigation,
  productDetail: ProductDetails,
  cart: Cart,
  checkout: CheckOut,
  simpleModal: SimpleModal,
  listContainer: ListContainer,
  userDetail: UserDetail,
  gallery: PhotoGallery,
  blogArticle: BlogArticle,
  wishlist: Wishlist,
  contentComponent: ContentComponent,
  promotionList: PromotionList,
  login: LoginForm,
  imagePromo: ImagePromo,
  carousel: Carousel,
  statementCard: StatementCard,
  myOrder: MyOrder,
  searchProducts: SearchProducts,
  carouselSlick: CarouselSlick,
  scheduler: Scheduler,
};

const HomePage = (props) => {
  const path = Config.default.slice(0, Config.default.lastIndexOf("/"));
  const [pageData, setPageData] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchJson = async () => {
      try {
        const res = await axios.get(path + props.jsonFile);
        if (res.data) {
          setPageData(res.data);
        }
      } catch (e) {}
    };
    if (props.jsonFile) fetchJson();
  }, [props.jsonFile, path]);
  useEffect(() => {
    dispatch(getProducts());
    dispatch(getBlogs());
    dispatch(getpromotions());
    if (window.localStorage.getItem("user")) {
      dispatch(loginUser(JSON.parse(window.localStorage.getItem("user"))));
    }
  }, [dispatch]);
  if (pageData) {
    var {
      banner,
      login,
      navigations,
      components,
      pagename,
      pagetype,
      col1class,
      col2class,
      col3class,
      pagetitle,
      headerCard,
      site,
    } = pageData;
  }
  if (pagetype) {
    var pageRenderFormat = pagetype.split("-");
  }
  if (navigations) {
    var leftnav = navigations.filter((nav) => nav.position === "left");
    var rightnav = navigations.filter((nav) => nav.position === "right");
    var topnav = navigations.filter((nav) => nav.position === "top");
    var bottomnav = navigations.filter((nav) => nav.position === "bottom");
    var leftnavcol, rightnavcol, centerCol;
    if (leftnav.length && rightnav.length) {
      leftnavcol = "col-2";
      rightnavcol = "col-2";
      centerCol = "col-8";
    } else if (leftnav.length) {
      leftnavcol = "col-2";
      centerCol = "col-10";
    } else if (rightnav.length) {
      rightnavcol = "col-2";
      centerCol = "col-10";
    }
  }

  const auth = Auth();

  if (props?.restricted && !auth) {
    const modalData = {
      title: "Please Login to access this page!",
      component: [{ login: { componentClass: "login-page-form" } }],
    };
    return <SimpleModal data={modalData} componentsMap={componentsMap} />;
  }
  if (!props.restricted || (props.restricted && auth))
    return (
      <main className="app-wrapper">
        <div id="container-div w-100 h-100"></div>
        <Helmet>
          <title>{pagetitle ? pagetitle : pagename}</title>
        </Helmet>
        {login && !auth && <LoginForm data={login} />}
        {banner ? (
          <Banner data={banner} />
        ) : (
          <div className="container">
            {pagetitle && <h1 className="mt-4">{pagetitle}</h1>}
            {headerCard && <SingleCard data={headerCard} />}
          </div>
        )}
        <div className="container">
          {topnav && (
            <div className="">
              <Navigation data={topnav} />
            </div>
          )}
          <div>
            {leftnav && (
              <div className={leftnavcol}>
                <Navigation data={leftnav} />
              </div>
            )}
            <div
              className={`row ${centerCol ? centerCol : ""}`}
              style={
                pageRenderFormat &&
                pageRenderFormat.length > 1 &&
                pageRenderFormat[1] === "reverse"
                  ? { flexDirection: "row-reverse" }
                  : { flexDirection: "row" }
              }
            >
              <div className="col-lg-8 col-md-6 col-12">
                {components &&
                  components.col0 &&
                  components.col0.map((item, idx) =>
                    Object.entries(item).map(function ([key, val]) {
                      const Comp = componentsMap[key];
                      return (
                        <div className="container-component new" key={idx}>
                          <Comp
                            data={val}
                            site={site}
                            componentsMap={componentsMap}
                          />
                        </div>
                      );
                    })
                  )}
              </div>

              <div
                className={
                  pageRenderFormat && pageRenderFormat[0] === "full"
                    ? `col-12`
                    : col1class
                    ? col1class
                    : `col-md-7 col-12`
                }
              >
                {components &&
                  components.col1 &&
                  components.col1.map((item, idx) =>
                    Object.entries(item).map(function ([key, val]) {
                      const Comp = componentsMap[key];
                      return (
                        <div className="container-component obh" key={idx}>
                          <Comp
                            data={val}
                            site={site}
                            componentsMap={componentsMap}
                          />
                        </div>
                      );
                    })
                  )}
              </div>
              <div className={col2class ? col2class : "col-md-5 col-12"}>
                {components &&
                  components.col2 &&
                  components.col2.map((item, idx) =>
                    Object.entries(item).map(function ([key, val]) {
                      const Comp = componentsMap[key];
                      return (
                        <div className="container-component col2" key={idx}>
                          <Comp data={val} site={site} />
                        </div>
                      );
                    })
                  )}
              </div>
            </div>
            <div className={col3class ? col3class : "col-md-12 col-12"}>
              {components &&
                components.col3 &&
                components.col3.map((item, idx) =>
                  Object.entries(item).map(function ([key, val]) {
                    const Comp = componentsMap[key];
                    return (
                      <div className="container-component col3" key={idx}>
                        <Comp data={val} site={site} />
                      </div>
                    );
                  })
                )}
            </div>

            {rightnav && (
              <div className={rightnavcol}>
                <Navigation data={rightnav} />
              </div>
            )}
          </div>
          {bottomnav && (
            <div className="">
              <Navigation data={bottomnav} />
            </div>
          )}
        </div>
      </main>
    );
};
export default HomePage;
