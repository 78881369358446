import { Badge, Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { removeAnItem, addAnItem, removeItem } from "../../store/cart-actions";

const Cart = (props) => {
  const items = useSelector((state) => state.cart.items);
  const total = useSelector((state) => state.cart.total);
  const title = "View Cart";
  const dispatch = useDispatch();
  const addAnItemToCart = (item) => {
    dispatch(addAnItem(item));
  };
  const removeAnItemFromCart = (id) => {
    dispatch(removeAnItem(id));
  };
  const removeItemFromCart = (id) => {
    dispatch(removeItem(id));
  };
  return (
    <>
      {!props?.data?.align && (
        <div className="cart-body">
          {items && items.length === 0 && (
            <div className="container text-center">
              <div className="fw-bold font-1_4 pb-3">Your Cart</div>
              <div className="pb-3">Your Cart is empty</div>
              <div>
                Continue Browsing{" "}
                <a className="font-black" href={props?.data?.itemPage}>
                  here
                </a>
              </div>
            </div>
          )}
          {items && items.length > 0 && (
            <>
              <Table className="cart-table">
                <thead>
                  <tr>
                    <th colSpan={2}>Product</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {items &&
                    items.map((item, idx) => {
                      return (
                        <tr className="cart-row" key={idx}>
                          <td className="text-center cart-item-image">
                            {item.img && (
                              <a href={`/product/${item.id}`}>
                                <img src={item.img} alt={item.name} />
                              </a>
                            )}
                          </td>
                          <td className="cart-item-name">
                            <div className="fw-bold">{item.name}</div>
                            <a
                              href="#remove"
                              className="d-flex font-black font-0_7"
                              onClick={removeItemFromCart.bind(null, item.id)}
                            >
                              Remove
                            </a>
                          </td>
                          <td className="cart-item-price" data-label="Price: ">
                            {item &&
                              !item.isSale &&
                              item.price &&
                              `$${item.price}`}
                            {item &&
                              item.isSale &&
                              item.price &&
                              item.salePrice && (
                                <>
                                  <s>${item.price}</s>
                                  <span className="font-secondary">
                                    {" "}
                                    now ${item.salePrice}
                                  </span>
                                </>
                              )}
                          </td>
                          <td
                            className="cart-item-quantity"
                            data-label="Quantity: "
                          >
                            <div className="input-group quantity-input">
                              <input
                                type="button"
                                value="-"
                                className="button-minus"
                                data-field="quantity"
                                onClick={removeAnItemFromCart.bind(
                                  null,
                                  item.id
                                )}
                              />
                              <input
                                type="number"
                                step="1"
                                max=""
                                value={item.quantity}
                                onChange={() => {}}
                                name="quantity"
                                className="quantity-field"
                              />
                              <input
                                type="button"
                                value="+"
                                className="button-plus"
                                data-field="quantity"
                                onClick={addAnItemToCart.bind(null, item)}
                              />
                            </div>
                          </td>
                          <td
                            className=" cart-item-amount"
                            data-label="Amount: "
                          >
                            ${item.amount}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <div className="cart-total">
                <div className="total">
                  <div className="subtotal-text">Subtotal : </div>${total} USD
                </div>
                <div className="font-0_7">
                  Taxes and shipping calculated at checkout
                </div>
                <a href="/checkout">
                  <Button className="mt-3">CheckOut</Button>
                </a>
              </div>
            </>
          )}
        </div>
      )}
      {props?.data?.align && items.length > 0 && (
        <div
          className={`cart-products-page ${props.data.hide ? "hide" : ""} ${
            props.data.align === "vertical" ? "flex-column" : "flex-row"
          }`}
        >
          <div className="cart-body border-bottom  mb-2 w-100">
            <h2 className="title">Your Cart</h2>
            <div className="d-flex py-3">
              <div className="fw-bold pe-2">Subtotal : </div>
              <div className="font-secondary fw-bold">${total} USD</div>
            </div>
          </div>
          <div
            className={`cart-items w-100 ${
              props.data.align === "vertical" ? "flex-column" : "flex-row"
            }`}
          >
            {items?.map((itm, idx) => (
              <div key={idx} className="cart-item ">
                {itm.img && (
                  <div className="d-flex border-bottom mb-2 pb-1">
                    <div className="w-25 me-2">
                      <img src={itm.img} alt={itm.name} className="w-100" />
                    </div>
                    <h6 className="fs-9 text-muted">${itm.name}</h6>
                    <h6 className="fs-9 title">${itm.price}</h6>
                    {/* <Badge className="bg-primary cart-item-badge">
                      {itm.quantity}
                    </Badge> */}
                  </div>
                )}
              </div>
            ))}
          </div>

          <a href="/cart">
            <Button
              onClick={() => {
                window.analytics.track("clicked View Cart ", {
                  title: "clicked View Cart",
                });
              }}
            >
              View Cart
            </Button>
          </a>
        </div>
      )}
    </>
  );
};
export default Cart;
