/*
/ Description: Schedule Appointment input: static JSON with available dates (can be dynamic if server exists)
/ Use case: add to staticwebsite where needs for scheduling an appointment on time and date base exists 
/ Output : selectedDate.toDateString() + selectedTime
/ Output can be saved or sent to Server if one exist
/ Author: Boban Stefanovic bstefanovic@teamhgs.com
/ Note: can be set per industry /vertical 
/ Requires: src/json/availableAppointments.json
*/

import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Scheduler() {
  const [availableAppointments, setAvailableAppointments] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState("");
  const [confirmationMessage, setConfirmationMessage] =
    useState("Reserve date");
  const [confirmation, setConfirmation] = useState(false);

  useEffect(() => {
    fetch("/json/availableAppointments.json")
      .then((response) => response.json())
      .then((data) => {
        setAvailableAppointments(data.available_appointments);
        const dates = data.available_appointments.map(
          (appointment) => new Date(appointment.date)
        );
        setAvailableDates(dates);
      });
  }, []);

  const sendData = () => {
    setConfirmationMessage("Confirmed");
    setConfirmation(true);
    alert("Confirmed : " + selectedDate.toDateString() + " at " + selectedTime);
  };
  const isDateAvailable = (date) => {
    return availableDates.some(
      (availableDate) =>
        date.getFullYear() === availableDate.getFullYear() &&
        date.getMonth() === availableDate.getMonth() &&
        date.getDate() === availableDate.getDate()
    );
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedTime("");
    const times = availableAppointments
      .filter(
        (appointment) =>
          new Date(appointment.date).toDateString() === date.toDateString()
      )
      .map((appointment) => appointment.time);
    setAvailableTimes(times);
  };

  return (
    <div className="container container-component">
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <div className="flex-column justify-content-center align-items-center d-flex w-100">
            <p className="mt-2 mb-4  ">Schedule an Appointment</p>
            <DatePicker
              wrapperClassName="w-full"
              selected={selectedDate}
              onChange={handleDateChange}
              filterDate={isDateAvailable}
              placeholderText="Select an available date"
              inline
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="flex-column justify-content-center align-items-center d-flex">
            {selectedDate && (
              <div className="flex-column justify-content-center align-items-center d-flex w-100">
                <p className="mt-2 mb-4 ">
                  Available Times for <b>{selectedDate.toDateString()}</b>:
                </p>
                <ul className="list-group w-100">
                  {availableTimes.map((time, index) => (
                    <li key={index} className="list-group-item">
                      <button
                        className="btn btn-secondary"
                        onClick={() => setSelectedTime(time)}
                        disabled={selectedTime === time}
                      >
                        {time}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {selectedTime && !confirmation && (
              <button
                className="btn btn-primary mt-4 mb-2 w-100"
                onClick={() => sendData()}
              >
                {confirmationMessage}: {selectedDate.toDateString()} at{" "}
                {selectedTime}
              </button>
            )}
            {confirmation && selectedTime && (
              <p className="alert alert-primary mt-4 w-100">
                {confirmationMessage}: {selectedDate.toDateString()} at{" "}
                {selectedTime}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Scheduler;
