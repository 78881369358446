import { useSelector } from "react-redux";
function User() {
  const accountData = useSelector((state) => state.myAccount.accountInfo);
  //console.log("UserData", accountData);
  const getUserInfo = () => {
    return accountData;
  };
  return getUserInfo();
}

export default User;
