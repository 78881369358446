import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import config from "../../config";
import User from "../../helpers/login/userData";
import { accountActions } from "../../store/my-account-slice";
import LoadingSpinner from "./LoadingSpinner";

/**
 *
 * @Container props.data{
 * title
 *
 */
const UserDetail = (props) => {
  if (props.data) {
    var { title } = props.data;
  }
  const u = User();
  const [user, setUser] = useState(u);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setUser(u);
  }, [isEditing]);

  const getRequest = (data) => {
    if (config.GET_USERUPDATE_API)
      axios
        .get(config.GET_USERUPDATE_API, {
          params: { ...data, userid: user.userid },
        })
        .then((res) => {
          setTimeout(() => {
            setIsEditing(false);
            if (res.data?.success == "true") {
              dispatch(accountActions.replaceAcountInfo(data));
              toast.success("User Information Update Successful", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            } else {
              toast.error("Could not update Information", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
            setIsLoading(false);
          }, 2000);
        })
        .catch((err) => {
          setIsLoading(false);
        });
  };
  const inputChangeHandler = (e, key) => {
    const u = { ...user };
    u[key] = e.target.value;
    setUser({ ...u });
  };
  const onFormSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    const formdata = Object.fromEntries(new FormData(e.target)?.entries());
    if (formdata) {
      getRequest(formdata);
    }
  };

  return (
    <div className="user-detail card">
      {isLoading && <LoadingSpinner />}
      <div className="user-title">
        <span className="title">{title}</span>
        <Button title="Edit User" onClick={() => setIsEditing(!isEditing)}>
          {!isEditing && <i className="fa-solid fa-pen-to-square"></i>}
          {isEditing && <i className="fa-solid fa-xmark"></i>}
        </Button>
      </div>
      {user && (
        <Form className="row form-container" onSubmit={onFormSubmit}>
          {Object.entries(user)
            .filter(([key, value]) => key != "userid")
            .map(([key, value], idx) => {
              if (key === "password") {
                return (
                  <Form.Group className={`mb-3 col-md-6 col-12`} key={idx}>
                    <Form.Label className="text-capitalize">{key}</Form.Label>
                    <div className="password-input">
                      <Form.Control
                        type={showPass ? "text" : "password"}
                        name={key}
                        value={value}
                        onChange={(e) => inputChangeHandler(e, key)}
                        disabled={!isEditing}
                      />
                      <i
                        onClick={() => setShowPass(!showPass)}
                        title={showPass ? "Hide Password" : "Show Password"}
                        className={showPass ? "fas fa-eye-slash" : "fas fa-eye"}
                      ></i>
                    </div>
                  </Form.Group>
                );
              }
              return (
                <Form.Group className={`mb-3 col-md-6 col-12`} key={idx}>
                  <Form.Label className="text-capitalize">{key}</Form.Label>
                  <Form.Control
                    type="text"
                    name={key}
                    onChange={(e) => inputChangeHandler(e, key)}
                    value={value}
                    disabled={!isEditing}
                  />
                </Form.Group>
              );
            })}
          {isEditing && (
            <Button className="col-4 offset-4" type="submit">
              Save
            </Button>
          )}
        </Form>
      )}
    </div>
  );
};

export default UserDetail;
