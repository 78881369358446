import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactOwlCarousel from "react-owl-carousel";
import BlogListCard from "../BlogListCard";
import CatalogItemCard from "../CatalogItemCard";
import PromotionList from "../PromotionList";
import SingleCard from "./card.single";
import ImagePromo from "./ImagePromo";
const componentMap = {
  simpleCard: SingleCard,
  catalogCard: CatalogItemCard,
  promotionList: PromotionList,
  blogListCard: BlogListCard,
  imagePromo: ImagePromo,
};
const Carousel = (props) => {
  const defaultCardType = "simpleCard";
  //console.log("PROPS CAROUSEL", props);
  if (props.data) {
    var { title, items, item0, item600, item1000 } = props.data;
  }
  return (
    <div className={props.data.className ? props.data.className : "caurosel "}>
      {title && <h2 className="title">{title}</h2>}
      <div>
        <div className="container">
          <ReactOwlCarousel
            className="owl-theme"
            loop
            dots
            margin={10}
            responsiveClass
            responsive={{
              0: { items: item0 },
              600: {
                items: item600,
              },
              1000: {
                items: item1000,
              },
            }}
          >
            {items.length > 0 &&
              items.map((item, index) => {
                const Comp =
                  componentMap[
                    item?.itemType ? item.itemType : defaultCardType
                  ];
                return <Comp key={index} data={item} />;
              })}
          </ReactOwlCarousel>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
