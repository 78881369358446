import axios from "axios";
import config from "../config";
import { productActions } from "./products-slice";
export const getProducts = () => {
  return (dispatch) => {
    axios
      .get(config.productsApi)
      .then((res) => {
        dispatch(productActions.replaceProducts(res.data));
      })
      .catch((err) => console.log("Error"));
  };
};

export const addToWishlist = (item) => {
  return (dispatch) => {
    dispatch(productActions.addToWishlist(item));
  };
};

export const removeFromWishlist = (item) => {
  return (dispatch) => {
    dispatch(productActions.removeFromWishlist(item));
  };
};
