import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const FourColCards = (props) => {
  function* chunks(array, n) {
    for (let i = 0; i < array.length; i += n) yield array.slice(i, i + n);
  }
  const chunkedByFour = [...chunks(props.data, 4)];
  return chunkedByFour.map((cardSet, index) => (
    <div className="row cards-4" key={index}>
      {cardSet.map((card, idx) => (
        <Card key={idx} className="col-xs-12 col-lg-3 col-sm-6  mt-2 ">
          {card?.img && (
            <Card.Img variant="top" src={card.img} className={card.imgClass} />
          )}
          <Card.Body>
            <Card.Title>{card?.title}</Card.Title>
            <Card.Text>{card?.content}</Card.Text>

            {card?.buttonLink && card?.buttonText ? (
              <a href={card.buttonLink}>
                <Button variant="primary" className="my-2">
                  {card.buttonText}
                </Button>
              </a>
            ) : (
              card.buttonText && (
                <Button variant="primary" className="my-2">
                  {card.buttonText}
                </Button>
              )
            )}
          </Card.Body>
        </Card>
      ))}
    </div>
  ));
};

export default FourColCards;
