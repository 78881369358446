import axios from "axios";
import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Routers from "./Routers";
import Config from "./config";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "react-responsive-modal/styles.css";

function App() {
  const [sitedata, setSiteData] = useState({});
  // const [projectNameStatic, setprojectNameStatic] = useState(
  //   JSON.parse(window.localStorage.getItem("projectName"))
  // );
  const [pages, setPages] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [footerData, setFooterData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [phoneData, setPhoneData] = useState({ phones: [] });
  const callCenter = JSON.parse(
    window.localStorage.getItem("staticCallCenter")
  );
  useEffect(() => {
    const fetchJson = async () => {
      const res = await axios.get(Config.default);
      if (res.data) {
        setSiteData(res.data.site);
        setPages(res.data.pages);
        setHeaderData(res.data.header);
        setFooterData(res.data.footer);
      }
    };
    // setProject();
    fetchJson();
  }, []);

  if (sitedata) {
    document.documentElement.style.setProperty(
      "--chatcolor",
      sitedata.chatcolor
    );
    document.documentElement.style.setProperty(
      "--headerNavigationColor",
      sitedata.headerNavigationColor
    );
    document.documentElement.style.setProperty(
      "--chatbackground",
      sitedata.chatbackground
    );
    document.documentElement.style.setProperty(
      "--chatcoloragent",
      sitedata.chatcoloragent
    );
    document.documentElement.style.setProperty(
      "--chatbackgroundagent",
      sitedata.chatbackgroundagent
    );

    document.documentElement.style.setProperty(
      "--footer-background-color",
      sitedata.backgroundFooter
    );
    document.documentElement.style.setProperty(
      "--header-background-color",
      sitedata.backgroundHeader
    );
    document.documentElement.style.setProperty(
      "--primary-color-dark",
      sitedata.colorPrimaryBckg
    );
    document.documentElement.style.setProperty(
      "--primary-color-light",
      sitedata.colorPrimaryText
    );
    document.documentElement.style.setProperty(
      "--primary-color-industry",
      sitedata.colorPrimaryBckg
    );
    document.documentElement.style.setProperty(
      "--secondary-color-dark",
      sitedata.colorSecondaryBckg
    );
    document.documentElement.style.setProperty(
      "--secondary-color-light",
      sitedata.colorSecondaryText
    );
    document.documentElement.style.setProperty(
      "--background-color-grey",
      sitedata.backgroundColorGrey
    );
    document.documentElement.style.setProperty(
      "--color-footer",
      sitedata.colorFooter
    );
    document.documentElement.style.setProperty(
      "--color-header",
      sitedata.colorHeader
    );
    document.documentElement.style.setProperty(
      "--color-grey-medium",
      sitedata.colorGreyMedium
    );

    if (sitedata?.headerFontFamily)
      document.documentElement.style.setProperty(
        "--font-family-headers",
        sitedata.headerFontFamily
      );
    if (sitedata?.contentFontFamily)
      document.documentElement.style.setProperty(
        " --font-family-content",
        sitedata.contentFontFamily
      );

    if (sitedata?.activeLinkColor)
      document.documentElement.style.setProperty(
        "--primary-active-link-color",
        sitedata?.activeLinkColor
      );
    document.title = sitedata.siteTitle;
  }
  return (
    <div
      id={`${
        JSON.parse(window.localStorage.getItem("staticProjectName"))
          ? JSON.parse(window.localStorage.getItem("staticProjectName"))
          : "container"
      }`}
      className={`${
        JSON.parse(window.localStorage.getItem("staticProjectName"))
          ? JSON.parse(window.localStorage.getItem("staticProjectName"))
          : "default"
      }`}
    >
      <BrowserRouter>
        {headerData && (
          <Header data={headerData} logo={sitedata.logo} sitedata={sitedata} />
        )}
        <Routers pages={pages} logo={sitedata.logo} />
        {footerData && (
          <Footer data={footerData} site={sitedata} pageData={pageData} />
        )}
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
