import { Row } from "react-bootstrap";
import CatalogItemCard from "../CatalogItemCard";
import CardSingle from "./card.single";
/**
 *  @Component props.data:
 * title
 * componentContent
 * cards[]
 */
const CardContainer = (props) => {
  return (
    props.data &&
    props.data.map((cardset, index) => (
      <Row
        key={index}
        className={
          props.data.className
            ? props.data.className + " component card-container"
            : "component card-container "
        }
      >
        {console.log("PROPS CardContainer", props)}
        {cardset.title && (
          <h2 className="title d-flex justify-content-center py-3">
            {" "}
            {cardset.title}
          </h2>
        )}
        {cardset.componentContent && (
          <div className="mt-2 mb-2 text-center">
            {cardset.componentContent}
          </div>
        )}
        {cardset.cards &&
          cardset.cards.map((card, idx) => (
            <CardSingle
              className="col-xs-12 col-lg-4 col-sm-6 single-card"
              data={card}
              key={idx}
            />
          ))}
        {cardset.catalogCard &&
          cardset.catalogCard.map((card, idx) => (
            <CatalogItemCard
              key={idx}
              data={card}
              className="col-xs-12 col-lg-3 col-sm-6 mt-2"
            />
          ))}
      </Row>
    ))
  );
};

export default CardContainer;
