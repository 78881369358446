import { configureStore, combineReducers } from "@reduxjs/toolkit";
import blogsSlice from "./blogs-slice";
import cartSlice from "./cart-slice";
import myAccountSlice from "./my-account-slice";
import productsSlice from "./products-slice";
import promotionsSlice from "./promotions-slice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const rootReducer = combineReducers({
  myAccount: myAccountSlice,
  product: productsSlice,
  cart: cartSlice,
  blog: blogsSlice,
  promotion: promotionsSlice,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
export default store;
