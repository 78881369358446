import { useState } from "react";
import { Button, Form } from "react-bootstrap";
/**
 *
 * @Container {*} props.data{
 *  title:
 *  details: object {price, iterestRate, years, downPayment}
 * }
 *
 */

const MortgageCalc = (props) => {
  if (props.data) {
    var { title, details } = props.data;
  }
  const [mortgageData, setMortgageData] = useState({
    ...details,
  });
  const calculateMortgage = (e) => {
    e.preventDefault();
    if (mortgageData) {
      const { price, interestRate, years, downPayment } = mortgageData;
      const principal = price - downPayment;
      const months = years * 12;

      const monthlyPay = parseFloat(
        (principal *
          ((interestRate / 1200) * Math.pow(1 + interestRate / 1200, months))) /
          (Math.pow(1 + interestRate / 1200, months) - 1)
      ).toFixed(2);
      setMortgageData({
        ...mortgageData,
        monthlyPay: monthlyPay,
        principal: principal,
        months: months,
      });
    }
  };
  return (
    <div className="card border-0 shadow p-3 mb-3">
      {title && (
        <div className="px-2 pb-3">
          <span className="title">{title}</span>
        </div>
      )}
      <div className="mx-3">
        <Form onSubmit={calculateMortgage}>
          <Form.Group className="mb-1" controlId="formCalcPrice">
            <Form.Label>
              Loan Value:{" "}
              {Number(mortgageData?.price).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </Form.Label>
            <input
              type="range"
              className="form-range"
              id="formCalcPrice"
              min={10000}
              max={details.limit}
              value={mortgageData.price}
              onChange={(e) =>
                setMortgageData({
                  ...mortgageData,
                  price: e.target.value,
                })
              }
            />
          </Form.Group>

          <Form.Group className="mb-1" controlId="formCalcInterest">
            <Form.Label>Interest Rate %</Form.Label>
            <Form.Control
              type="number"
              value={mortgageData?.interestRate}
              onChange={(e) =>
                setMortgageData({
                  ...mortgageData,
                  interestRate: e.target.value,
                })
              }
            />
          </Form.Group>
          <Form.Group className="mb-1" controlId="formCalcYears">
            <Form.Label>Years</Form.Label>
            <Form.Control
              type="number"
              value={mortgageData?.years}
              onChange={(e) =>
                setMortgageData({
                  ...mortgageData,
                  years: e.target.value,
                })
              }
            />
          </Form.Group>
          <Form.Group className="mb-1" controlId="formCalcDownpay">
            <Form.Label>Down Payment</Form.Label>
            <Form.Control
              type="number"
              value={mortgageData?.downPayment}
              onChange={(e) =>
                setMortgageData({
                  ...mortgageData,
                  downPayment: e.target.value,
                })
              }
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="my-2">
            Calculate
          </Button>
        </Form>
        {mortgageData?.months && (
          <div className="py-2  mt-3 bg-light bg-gradient p-2 rounded  ">
            <div className="d-flex  justify-content-between  mb-2 border-bottom">
              <span>Principal</span>
              <span className="fw-bold">
                {mortgageData?.principal?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </span>
            </div>
            <div className="d-flex justify-content-between mb-2 border-bottom">
              <span>Total Payments</span>
              <span className="fw-bold text-primary">
                {mortgageData?.months}
              </span>
            </div>
            <div className="d-flex  justify-content-between  mb-2 border-bottom">
              <span>Monthly Payments</span>
              <span className="fw-bold">
                {Number(mortgageData?.monthlyPay).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default MortgageCalc;
