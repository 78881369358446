import { useSelector } from "react-redux";
function Auth() {
  const isLoggedIn = useSelector((state) => state.myAccount.isLoggedIn);
  const isAuthenticated = () => {
    return isLoggedIn;
  };
  return isAuthenticated();
}

export default Auth;
