import axios from "axios";
import config from "../config";
import { promotionActions } from "./promotions-slice";
export const getpromotions = () => {
  return (dispatch) => {
    axios
      .get(config.promotionsApi)
      .then((res) => {
        dispatch(promotionActions.replacePromotions(res.data));
      })
      .catch((err) => console.log("Error"));
  };
};
