import { Accordion } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import BlogListCard from "../BlogListCard";
import SingleCard from "./card.single";
import Navigation from "./navigation";

const BlogArticle = (props) => {
  var categories = [
    ...Object.assign(
      [],
      useSelector((state) => state.blog.blogs.categories)
    ),
  ];
  const articles = useSelector((state) => state.blog.blogs.articles);
  const params = useParams();

  if (props?.data) {
    var navigations = [...props.data.navigations];
  }
  categories = categories.map((cat) => {
    cat = Object.assign([], cat);
    cat.name = cat.title;
    cat.url = `/blogs/${cat.title}`;
    return cat;
  });
  navigations.map((nav) => {
    nav.items = categories;
    return nav;
  });

  if (params) {
    var { catName, artName } = params;
  }
  const findCategoryAndArticles = (catName, artName) => {
    var categoryData = categories?.find(({ title }) => title == catName);
    var cat;
    if (artName)
      cat = {
        ...articles?.find(
          ({ category, title }) =>
            category == categoryData.id && title == artName
        ),
      };
    var filteredArticles = [
      ...articles?.filter(
        (art) =>
          art.category == categoryData?.id &&
          art.parent == (artName ? cat.id : 0)
      ),
    ];
    if (filteredArticles?.length == 0) {
      filteredArticles = [
        ...articles?.filter(
          (art) =>
            art.category == categoryData.id &&
            art.parent == cat.parent &&
            art.id != cat.id
        ),
      ];
    }

    var articlesForCatId = [
      ...filteredArticles?.map((art) => {
        art = Object.assign([], art);
        art.cardLink = `/blogs/${categoryData.title}/${art?.title}`;
        art.category = categories?.find((cat) => cat.id == art.category);
        art.content = art.teaser;
        return art;
      }),
    ];
    if (artName) return { category: cat, articlesForCatId };
    else return { category: categoryData, articlesForCatId };
  };

  var category, articlesForCatId;
  if (categories?.length > 0 && articles?.length > 0) {
    if (catName && !artName) {
      ({ category, articlesForCatId } = findCategoryAndArticles(
        catName,
        artName
      ));
    }
    if (catName && artName) {
      ({ category, articlesForCatId } = findCategoryAndArticles(
        catName,
        artName
      ));

      category.imgClass = "blog-card-img";
    }
  }

  return (
    <>
      <Accordion className="blog-accordion">
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <div className="title">Blog Categories</div>
          </Accordion.Header>
          <Accordion.Body className="d-flex flex-column">
            {categories?.map((cat, idx) => (
              <a href={`/blogs/${cat.title}`} key={idx} className="py-2">
                <img
                  style={{ width: "8rem" }}
                  className="pe-2"
                  alt=""
                  src={cat.img}
                />
                {cat.title}
              </a>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="blog-list">
        <Navigation data={navigations} />
      </div>

      <div className="blog-article">
        {category && <SingleCard data={category} />}
        {/* {articlesForCatId && <CardContainer data={articlesForCatId} />} */}
        <div>
          {articlesForCatId?.map((art, idx) => {
            return <BlogListCard key={idx} data={art} />;
          })}
        </div>
      </div>
    </>
  );
};

export default BlogArticle;
