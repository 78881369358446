import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form, InputGroup } from "react-bootstrap";
import CardSingle from "../components/CatalogItemCard";
import LoadingSpinner from "../components/Common/LoadingSpinner.jsx";
/**
 *
 * @param {*} props.data
 * {
 *       "searchProducts": {
 *         "title": "We are your complete solution",
 *         "subTitle": "Find a vehicle you dream of",
 *         "mode": "pulledUp",
 *         "mode_comment": "pulledUp|normal|halfWide"
 *       }
 *     },
 */
const SearchProducts = (props) => {
  const [prod, setProd] = useState({});
  const [searchResultsArray, setSearchResultsArray] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [view, setView] = useState(false);
  const products = useSelector((state) => state.product.products);
  if (props.data) {
    var { id, mode, title, subTitle } = props.data;
  }

  useEffect(() => {
    setProd(products.find((p) => p.id === id));
  }, [products.size, products, prod, id]);

  useEffect(() => {
    setView(false);
    //you can add deBouncing fnx with setTimeout() to not call api anytime user types
    const deBouncingFnx = setTimeout(() => {
      if (searchTerm?.length >= 3) {
        setSearchResultsArray([]);
        SearchLogic();
      }
    }, 5000);

    // make sure you reset timer if using de-bouncer return () => clearTimeout(deBounceFnx)
    return () => {
      clearTimeout(deBouncingFnx);
    };
  }, [searchTerm]);

  // To Be Done
  const SearchLogic = () => {
    //once term set this piece will return array of found products

    const filteredProducts = products.filter((product) => {
      return (
        product?.description
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        product?.name?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
    setSearchResultsArray(filteredProducts);
    setView(true);
  };

  return (
    <div className={mode ? mode : "normal"}>
      <h2>{title}</h2>
      <p>{subTitle}</p>
      <InputGroup>
        <Form.Control
          type="text"
          placeholder="Search here.."
          defaultValue={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={(e) => setSearchTerm(e.target.value)}
        />
        <InputGroup.Text>
          <i
            className="fa-solid fa-magnifying-glass"
            onClick={(e) => SearchLogic()}
          ></i>
        </InputGroup.Text>
      </InputGroup>
      {view ? (
        <div className="card my-2 p-4 bg-light">
          {searchResultsArray?.length > 0 ? (
            <p>
              Found: <b>{searchResultsArray?.length}</b> results for term{" "}
              <b>{searchTerm}</b>
            </p>
          ) : (
            <p>
              Found: <b>{searchResultsArray?.length}</b> results for term{" "}
              <b>{searchTerm}</b>
            </p>
          )}
          <div className="component card-container row">
            {searchResultsArray?.length > 0 &&
              searchResultsArray.map((product, i) => {
                return (
                  <CardSingle
                    className="col-xs-12 col-lg-3 col-sm-4 single-card p-4 "
                    data={product}
                    link={product.id}
                    key={i}
                  />
                );
              })}
            {searchResultsArray
              ? console.log("searchResultsArray", searchResultsArray)
              : null}
          </div>
        </div>
      ) : (
        searchTerm && (
          <div
            className=" mt-4 d-flex text-center flex-column justify-content-center align-items-center"
            style={{ width: "100%", height: "100%" }}
          >
            <div className="loaderSpin mt-2 "></div>
            <p> Searching for {searchTerm}</p>
          </div>
        )
      )}
    </div>
  );
};

export default SearchProducts;
