import axios from "axios";
import config from "../config";
import { blogActions } from "./blogs-slice";
export const getBlogs = () => {
  return (dispatch) => {
    axios
      .get(config.blogsApi)
      .then((res) => {
        dispatch(blogActions.replaceBlogs(res.data));
      })
      .catch((err) => console.log("Error"));
  };
};
