import { useEffect, useLayoutEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Auth from "../../helpers/login/auth";
import { addAnItem } from "../../store/cart-actions";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../store/products-actions";

const ProductDetails = (props) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  const location = useLocation();
  const navigate = useNavigate();
  const gotoURL = (url) => {
    navigate(url);
  };

  const params = useParams();
  const dispatch = useDispatch();
  const [prod, setProd] = useState({});
  const products = useSelector((state) => state.product.products);
  if (params) {
    var { id } = params;
  }
  useEffect(() => {
    setProd(products.find((p) => p.id === id));
  }, [products.size, products, prod, id, props]);

  const auth = Auth();
  const addToCart = (e) => {
    toast(prod.name + " added to cart.", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    window.analytics.track("clicked Add to Cart ", {
      title: "Add to cart:" + prod?.name,
    });
    dispatch(addAnItem(prod));
  };
  const addProdToWishlist = (e) => {
    dispatch(addToWishlist(prod));
    window.analytics.track("clicked Add to wish list ", {
      title: "Add to wish List:" + prod?.name,
    });
  };
  const removeProdFromWishlist = (e) => {
    window.analytics.track("clicked Remove from wish list ", {
      title: "Remove from wish List:" + prod?.name,
    });
    dispatch(removeFromWishlist(prod));
  };

  return (
    <div className="product-detail">
      {prod?.name && (
        <Helmet>
          <title>{prod?.name}</title>
        </Helmet>
      )}
      <div>
        <div className="row">
          {prod && prod.img && (
            <div className="col-md-6 col-12">
              <img
                src={prod.img}
                alt={prod.name}
                className="prodimg"
                width="100%"
              />
              <div className="buttons mt-2">
                {auth && !prod?.inWishlist && (
                  <Button
                    variant="dark"
                    className="me-2 my-2"
                    onClick={addProdToWishlist}
                  >
                    <i className="fa-regular fa-heart"></i> Wishlist
                  </Button>
                )}
                {auth && prod?.inWishlist && (
                  <Button
                    variant="dark"
                    className="me-2"
                    onClick={removeProdFromWishlist}
                  >
                    <i className="fa-solid fa-heart"></i> Remove from Wishlist
                  </Button>
                )}
                {/* {!auth && (
                  <span>
                    Please <a href="/login">login</a> to add items to wishlist
                  </span>
                )} */}
                <Button className=" me-2 " onClick={addToCart}>
                  {" "}
                  Add to Cart
                </Button>

                {prod?.sellLink && (
                  <Button
                    variant="success"
                    className="me-2"
                    onClick={() => gotoURL("/" + prod.sellLink)}
                  >
                    Sell similar?
                  </Button>
                )}
              </div>
            </div>
          )}
          <div className="col-md-6 col-12 d-flex flex-column align-items-start position-relative">
            {prod && prod.name && (
              <span className="fw-bold fs-4 mb-3 title">{prod.name}</span>
            )}
            {prod && prod.description && (
              <div
                dangerouslySetInnerHTML={{
                  __html: prod.description,
                }}
              ></div>
            )}
            {prod && !prod.isSale && prod.price && (
              <p className="fw-bold price badge bg-danger ">${prod.price}</p>
            )}
            {prod && prod.isSale && prod.price && prod.salePrice && (
              <>
                <s>${prod.price}</s>
                <span className="text-primary"> now ${prod.salePrice}</span>
              </>
            )}
            <div className="buttons">
              {auth && !prod?.inWishlist && (
                <Button
                  variant="dark"
                  className="me-2 my-2"
                  onClick={addProdToWishlist}
                >
                  <i className="fa-regular fa-heart"></i> Wishlist
                </Button>
              )}
              {/* 
              {!auth && (
                <span>
                  Please <a href="/login">login</a> to add items to wishlist
                </span>
              )} */}
              <Button className=" me-2 " onClick={addToCart}>
                {" "}
                Add to Cart
              </Button>
            </div>

            {prod?.reviews?.length > 0 && (
              <div className="reviews mt-6">
                {prod?.reviews?.map((item, a) => {
                  return (
                    <div
                      key={a}
                      className="d-flex flex-column justify-content-between align-items-left pb-2  border-bottom my-4"
                    >
                      <div className="ratings text-warning mt-6">
                        {item.rating === "1" && (
                          <>
                            <i className="fa fa-star"></i>{" "}
                            <div className="d-flex justify-content-between text-dark">
                              <b>{item.title}</b>
                              <p className="review-date font-grey fs-9 me-2">
                                {item.date}
                              </p>
                            </div>
                          </>
                        )}
                        {item.rating === "2" && (
                          <>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>{" "}
                            <div className="d-flex justify-content-between text-dark">
                              <b>{item.title}</b>
                              <p className="review-date font-grey fs-9 me-2">
                                {item.date}
                              </p>
                            </div>
                          </>
                        )}
                        {item.rating === "3" && (
                          <>
                            {" "}
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>{" "}
                            <div className="d-flex justify-content-between text-dark">
                              <b>{item.title}</b>
                              <p className="review-date font-grey fs-9 me-2">
                                {item.date}
                              </p>
                            </div>
                          </>
                        )}
                        {item.rating === "4" && (
                          <>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>{" "}
                            <div className="d-flex justify-content-between text-dark">
                              <b>{item.title}</b>
                              <p className="review-date font-grey fs-9 me-2">
                                {item.date}
                              </p>
                            </div>
                          </>
                        )}
                        {item.rating === "5" && (
                          <>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <div className="d-flex justify-content-between text-dark">
                              <b>{item.title}</b>
                              <p className="review-date font-grey fs-9 me-2">
                                {item.date}
                              </p>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="row">
                        <span>{item.content}</span>
                      </div>
                      <div className="row"></div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className="row"></div>
      </div>
    </div>
  );
};
export default ProductDetails;
