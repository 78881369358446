import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import config from "../../config";
import { loginUser } from "../../store/my-account-actions";
import LoadingSpinner from "./LoadingSpinner";
const LoginForm = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const getRequest = (data) => {
    if (config.GET_LOGIN_API)
      axios
        .get(config.GET_LOGIN_API, {
          params: data,
        })
        .then((res) => {
          setTimeout(() => {
            setIsLoading(false);
            if (res.data?.success == "true") {
              setLoginError(false);
              delete res.data.success;
              dispatch(loginUser(res.data));

              window.localStorage.setItem("user", JSON.stringify(res.data));
              toast.success(`${email} is logged in`, {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              if (location.pathname && location.pathname !== "/login")
                navigate(location.pathname);
              else navigate("/");
            } else {
              setLoginError(true);
            }
          }, 2000);
        })
        .catch((err) => {
          setLoginError(true);
          setIsLoading(false);
        });
  };
  const login = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formdata = Object.fromEntries(new FormData(e.target)?.entries());
    if (formdata) {
      getRequest(formdata);
    }
  };
  if (props.data) {
    var { componentClass } = props.data;
  }
  const project = window.localStorage
    .getItem("staticProjectName")
    .replace(/["']/g, "");
  return (
    <>
      {isLoading && <LoadingSpinner />}

      <form onSubmit={login} className="login-form">
        {/* <a href="/home">
          <img className="logo" src={`/images/${project}/logo.png`} alt="" />
        </a> */}

        <div
          className={`login  d-flex justify-content-center ${
            componentClass ? componentClass : ""
          }`}
        >
          <div className="login-overlay justify-content-center">
            <span className="title">Sign In</span>

            <div className="row g-3 mb-9">
              <div className="col-md-6">
                <a
                  href="#"
                  className="social btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100"
                >
                  <img
                    alt="Logo"
                    src="https://preview.keenthemes.com/metronic8/demo1/assets/media/svg/brand-logos/google-icon.svg"
                    className="h-15px me-3"
                  />
                  Sign in with Google
                </a>
              </div>

              <div className="col-md-6">
                <a
                  href="#"
                  className="social btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100"
                >
                  <img
                    alt="Logo"
                    src="https://preview.keenthemes.com/metronic8/demo1/assets/media/svg/brand-logos/apple-black.svg"
                    className="theme-light-show h-15px me-3"
                  />
                  Sign in with Apple
                </a>
              </div>
            </div>

            <div className="separator separator-content my-14">
              <span className="w-125px text-gray-500 fw-semibold fs-7">
                Or with email
              </span>
            </div>
            {loginError && (
              <div className="error">
                <i className="fa-solid fa-circle-exclamation"></i> Invalid Login
                Credentials
              </div>
            )}
            <input
              type="text"
              className="form-control my-2"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              aria-describedby="userHelp"
              placeholder="Email"
              required
            />
            <input
              type="password"
              className="form-control mb-2"
              name="password"
              id="exampleInputPassword1"
              placeholder="Password"
              required
            />
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
              />
              <label className="form-check-label">Remember me</label>
            </div>
            <button
              type="submit"
              // onClick={loginClick}
              onClick={() => {
                window.analytics.track("clicked User Login", {
                  title: "Login Form",
                });
              }}
              className="btn btn-primary mt-2 login-button"
            >
              Sign in
            </button>
            <div className="login-links mt-1">
              <a href="/home">Forgot ID/Password? »</a>
              <br />
              <a href="/createAccount">Open an Account »</a>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
export default LoginForm;
