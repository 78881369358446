import { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import User from "../helpers/login/userData";
import { useDispatch, useSelector } from "react-redux";
/* 
{
        "netoCalculator": {
          "title": "Net Calculator",
          "currency": "en-US",
          "locale":"USD", 
          "details": [
            {
              "id": 1,
              "value": 6250,
              "name": "Gross Salary",
              "placeholder": "Enter Gross Salary",
              "type": "income"
            },
            {
              "id": 2,
              "value": 1300,
              "name": "Pension",
              "type": "income"
            },
            { "id": 3, "value": 2500, "name": "Income Tax", "type": "expense" },
            {
              "id": 4,
              "value": 430,
              "name": "National Insurance",
              "type": "expense"
            },
            { "id": 5, "value": 2300, "name": "Mortgage", "type": "expense" },
            { "id": 6, "value": 50, "name": "Council Tax", "type": "expense" },
            { "id": 7, "value": 200, "name": "Utilities", "type": "expense" },
            { "id": 8, "value": 500, "name": "Groceries", "type": "expense" },
            { "id": 9, "value": 815, "name": "Credit Cards", "type": "expense" }
          ]
        }
 */

const NetoCalculator = (props) => {
  var { title, details, currency, locale, desc } = props.data;
  const user = User();
  const [inputFields, setInputFields] = useState(details);
  //if (details?.length > 0) setInputFields(props.data?.details);

  const handleAddField = (name, e) => {
    const newInputField = {
      id: inputFields.length + 1,
      value: Number(e.target.value),
      name: name + "_" + (inputFields.length + 1),
      mode: name,
    };

    setInputFields([...inputFields, newInputField]);
    setcalculatorData([...calculatorData, newInputField]);
  };
  const [calculatorData, setcalculatorData] = useState([...inputFields]);
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);

  // useEffect(() => {
  //   console.log("inputFields", inputFields);
  // }, [inputFields]);

  // useEffect(() => {
  //   console.log("calculatorData", calculatorData);
  // }, [calculatorData]);

  const updateValue = (e) => {
    console.log("TARGET", e.target.name);
    const item = {
      id: Number(e.target.id),
      value: Number(e.target.value),
      name: e.target.name,
      mode: e.target.title,
    };
    const index = calculatorData.findIndex(
      (oldItem) => oldItem.name === e.target.name
    );
    var newcalculatorData = [...calculatorData]; // important to create a copy, otherwise you'll modify state outside of setState call
    newcalculatorData[index] = item;
    setcalculatorData(newcalculatorData);
  };
  const calculateData = (e) => {
    e.preventDefault();
    var sumIncome = 0;
    var sumExpense = 0;

    calculatorData.map((item, i) => {
      return item?.mode === "income"
        ? (sumIncome = sumIncome + item.value)
        : (sumExpense = sumExpense + item.value);
    });

    setTotalExpense(sumExpense);
    setTotalIncome(sumIncome);
  };
  return (
    <div className="card border-0 shadow p-3 mb-3">
      {title && (
        <div className="pb-3">
          <span className="title">{title}</span>
          <p className="mt-4">
            <b>{user?.fullname}</b>,{" "}
          </p>
          <p>{desc}</p>
        </div>
      )}
      <div className="calculator">
        <div className="card p-3 bg-light mb-4">
          <div className="d-flex p-2  justify-content-between border-bottom">
            <span>Total Income: </span>
            <span>
              {Number(totalIncome).toLocaleString(locale, {
                style: "currency",
                currency: currency,
              })}
            </span>
          </div>
          <div className="d-flex p-2 justify-content-between  border-bottom mb-4">
            <span>Total Expenses: </span>
            <span>
              {Number(totalExpense).toLocaleString(locale, {
                style: "currency",
                currency: currency,
              })}
            </span>
          </div>
          <div
            className={
              totalIncome - totalExpense > 0
                ? "alert alert-primary d-flex p-2 justify-content-between  border-bottom mb-4"
                : "alert alert-danger d-flex p-2 justify-content-between  border-bottom mb-4"
            }
          >
            <span>Total NET </span>

            <span>
              {Number(totalIncome - totalExpense).toLocaleString(locale, {
                style: "currency",
                currency: currency,
              })}
              {/* {Number(totalIncome - totalExpense).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })} */}
            </span>
          </div>
        </div>
        <Form onSubmit={calculateData} className="row">
          <div className="col-4">
            <div className="title fw-bold mb-4 fs-5  border-bottom ">
              {" "}
              Income{" "}
            </div>
            {inputFields.map(
              (inputField) =>
                inputField.mode === "income" && (
                  <Form.Group
                    className="mb-1 col-6 "
                    // controlId={inputField.id}
                    key={inputField.id}
                  >
                    <Form.Label>{inputField.name}</Form.Label>
                    <Form.Control
                      key={inputField.id}
                      type="text"
                      name={inputField.name}
                      id={inputField.id}
                      title={inputField.mode}
                      placeholder={inputField.placeholder}
                      defaultValue={inputField.value}
                      onChange={(e) => updateValue(e)}
                    />
                  </Form.Group>
                )
            )}
            <div className="border-top mt-4 pt-4">
              <Button
                variant="secondary"
                type="submit"
                className="col-md-12 col-lg-6  "
                onClick={(e) => handleAddField("income", e)}
              >
                + Income
              </Button>
            </div>
          </div>
          <div className="col-8">
            <div className="fw-bold mb-4 fs-5  border-bottom title border-left ">
              Expenses
            </div>
            <div className="row">
              {inputFields.map(
                (inputField) =>
                  inputField.mode === "expense" && (
                    <Form.Group
                      className="mb-1 col-6 "
                      // controlId={inputField.id}
                      key={inputField.id}
                    >
                      <Form.Label>{inputField.name}</Form.Label>
                      <Form.Control
                        key={inputField.id}
                        type="text"
                        name={inputField.name}
                        title={inputField.mode}
                        id={inputField.id}
                        placeholder={inputField.placeholder}
                        defaultValue={inputField.value}
                        onChange={(e) => updateValue(e)}
                      />
                    </Form.Group>
                  )
              )}{" "}
            </div>
            <div className="border-top mt-4 pt-4 mb-4">
              <Button
                variant="secondary"
                type="submit"
                className="col-md-12 col-lg-6 "
                onClick={(e) => handleAddField("expense", e)}
              >
                + Expense
              </Button>
            </div>
          </div>

          <div className="row ">
            <Button variant="primary" type="submit" className="m-2 col-12">
              Calculate
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default NetoCalculator;
