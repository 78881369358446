import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const MyOrder = (props) => {
  // const [orderedProducts, setOrderedProducts] = useState({});
  const products = useSelector((state) => state.product.products);
  if (props.data) {
    var { title } = props.data;
    var { items } = props.data;
  }

  const [orderedProducts, setOrderedProducts] = useState(
    products.filter((p1) => items.some((p2) => p1.id === p2.id))
  );

  console.log("orderedProducts", orderedProducts);

  return (
    <>
      <div className="user-title mb-2">
        <span className="title">{title}</span>
      </div>
      <div className="user-title bg-light px-3 pt-4 pb-3">
        {orderedProducts &&
          orderedProducts?.map((prod) => {
            return (
              <div className="card p-3 bg-white border-0 mb-3">
                <div className="lh-sm mb-1">
                  <span className="d-block text-success fw-bold lh-1">
                    {prod.isSale ? "Delivered" : "Out for Delivery"}
                  </span>
                  <small className="d-block lh-sm">On Tue, 9 Jan</small>
                </div>
                <a className={`img__wrap `} href={`/product/${prod?.id}`}>
                  <div className="d-flex bg-light p-2">
                    {prod.img && (
                      <div className="w-10">
                        <div className="ratio ratio-1x1">
                          <img
                            className="img__img center w-100"
                            src={prod.img}
                            alt={prod.name}
                          />
                        </div>
                      </div>
                    )}
                    <div className="px-4 pt-1">
                      <div className="fw-bold">{prod.name}</div>
                      <span>${prod.price}</span>
                    </div>
                  </div>
                </a>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default MyOrder;
