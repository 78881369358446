import SingleCard from "./card.single";

const ImagePromo = (props) => {
  if (props.data) {
    var {
      model,
      imageSide,
      title,
      content,
      img,
      id,
      imgClass,
      buttonText,
      buttonLink,
      secondaryButtonText,
      secondaryButtonLink,
    } = props.data;
  }
  const textHtml = (
    <div
      className={`col-md-6 col-12 img-promo-text ${model}  ${
        imageSide == "right" ? "order-md-first order-last" : ""
      }`}
    >
      <SingleCard
        data={{
          id,
          title,
          content,
          buttonText,
          buttonLink,
          secondaryButtonText,
          secondaryButtonLink,
        }}
      />
    </div>
  );

  const imageHtml = (
    <div className={`col-md-6 text-center`}>
      <img src={img} className={imgClass ? imgClass : ""} />
    </div>
  );

  return (
    <div className="row">
      {imageSide === "left" ? (
        <>
          {imageHtml}
          {textHtml}
        </>
      ) : (
        <>
          {textHtml}
          {imageHtml}
        </>
      )}
    </div>
  );
};

export default ImagePromo;
