const LoadingSpinner = (props) => {
  return (
    <div className="parentDisable" style={{ width: "100%", height: "100%" }}>
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
      <div className="loader  mt-2"> {props?.text}</div>
    </div>
  );
};
export default LoadingSpinner;
