import { ProgressBar } from "react-bootstrap";
import FormContainer from "./Common/formContainer";
import Scheduler from "../components/Scheduler";
/**
 * @Components props.data
 * title,
 * progress: progress bar percent
 * formContainer[]: form related info
 */
const ApplicationProcess = (props) => {
  if (props?.data) {
    var { title, progress, formContainer, scheduler } = props.data;
  }
  return (
    <div className="card border-0 shadow">
      {title && (
        <div className="card-header bg-grey">
          <div className="form_label py-2">{title}</div>
          {progress && <ProgressBar now={progress} label={`${progress}%`} />}
        </div>
      )}
      <div className="card-body">
        {scheduler && <Scheduler />}
        <FormContainer data={formContainer} />
      </div>
    </div>
  );
};

export default ApplicationProcess;
