import { Row } from "react-bootstrap";
import BlogListCard from "../BlogListCard";
import CatalogItemCard from "../CatalogItemCard";
import PromotionList from "../PromotionList";
import SingleCard from "./card.single";
import SimpleModal from "./modal";

const componentMap = {
  simpleCard: SingleCard,
  catalogCard: CatalogItemCard,
  promotionList: PromotionList,
  blogListCard: BlogListCard,
  simpleModal: SimpleModal,
};
const defaultCardType = "simpleCard";
const defaultNumberOfCols = 3;
const ContentComponent = (props) => {
  //   const getColClassName = (number) => {
  //     var classname = "col-sm-12 ";
  //     if (number > 1) {
  //       classname += "col-md-6 ";
  //     }
  //     if (number > 2 && 12 % number != 0) {
  //       classname += "col-lg";
  //     }
  //     if (number > 2 && 12 % number == 0) {
  //       classname += "col-lg-" + 12 / number;
  //     }
  //     return classname;
  //   };
  function* chunks(array, n) {
    for (let i = 0; i < array.length; i += n) yield array.slice(i, i + n);
  }
  //console.log("PROPS CONTENTCOMPONENT", props);
  return (
    props.data &&
    props.data.map((cardSet, index) => {
      if (cardSet) {
        const { items, numberOfCols } = cardSet;
        if (items?.length > 0) {
          var chunkedArray = [
            ...chunks(items, numberOfCols ? numberOfCols : defaultNumberOfCols),
          ];
        }
      }
      return (
        <Row
          key={index}
          className={
            cardSet.className
              ? cardSet.className +
                " card-container container-component center cc "
              : " card-container container-component center cc "
          }
        >
          {cardSet.title && <h2>{cardSet.title}</h2>}
          {cardSet.componentContent && (
            <div className="mt-2 mb-2 text-center">
              {cardSet.componentContent}
            </div>
          )}
          {chunkedArray &&
            chunkedArray?.map((data, idx) => (
              <Row key={idx} className="mx-0 ">
                {[
                  ...Array(
                    cardSet.numberOfCols
                      ? cardSet.numberOfCols
                      : defaultNumberOfCols
                  ),
                ].map((item, i) => {
                  const Comp =
                    componentMap[
                      data[i]?.itemType ? data[i].itemType : defaultCardType
                    ];
                  return (
                    <div className="col-sm-12 col-lg" key={i}>
                      {data[i] && (
                        <Comp
                          data={data[i]}
                          componentsMap={props?.componentsMap}
                        />
                      )}
                    </div>
                  );
                })}
              </Row>
            ))}
        </Row>
      );
    })
  );
};
export default ContentComponent;
