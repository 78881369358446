import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

/**
 *
 * @param {*} props.data
 * id -> product id from products.json
 */
const CatalogItemCard = (props) => {
  const [prod, setProd] = useState({});
  const products = useSelector((state) => state.product.products);
  if (props.data) {
    var { id } = props.data;
  }
  useEffect(() => {
    setProd(products.find((p) => p.id === id));
  }, [products.size, products, prod, id]);
  if (prod)
    return (
      <a
        className={`img__wrap ${props?.className ? props.className : ""}`}
        href={`/product/${id}`}
      >
        {prod.img && (
          <div className="position-relative text-center row">
            <img
              className="img__img center w-100 col-12"
              src={prod.img}
              alt=""
            />
            <div className="col-12">
              {prod.name && <p className="fw-normal fs-9">{prod.name}</p>}
              {prod.category && (
                <div className="font-1_4 py-2 text-center category">
                  {prod.category}
                </div>
              )}
              {prod.price && (
                <span className="price badge bg-danger ">${prod.price}</span>
              )}
            </div>
          </div>
        )}
        <div className="img__description_layer">
          {/* {prod.name && <p className="fw-bold ">{prod.name}</p>} */}
          {/* {!prod.isSale && prod.price && <p>${prod.price}</p>} */}
          {prod.salePrice && (
            <button className="btn btn-primary">See details</button>
          )}
        </div>
        {/* {isSale && <span className="triangle">Sale</span>} */}
      </a>
    );
};

export default CatalogItemCard;
