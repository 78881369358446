import { Link } from "react-router-dom";

/**
 *
 * @Container props.data{
 * title
 * content
 * details: object of key value pairs to be displayed
 * }
 *
 */
const ContactDetail = (props) => {
  const { title, content, details } = props.data;

  return (
    <>
      <span className="contact-title">{title}</span>
      <span className="py-3 d-block">{content}</span>
      {details &&
        Object.entries(details).map(([key, value], idx) => {
          return (
            <span key={idx} className="d-block text-capitalize">
              {key} : <a href="/contact">{value}</a>
            </span>
          );
        })}
    </>
  );
};

export default ContactDetail;
