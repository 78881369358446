import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Button } from "react-bootstrap";
var parse = require("html-react-parser");
/**
 *  @Component props.data:
 * title (string)
 * content (string)
 * component(array)
 */
class SimpleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //if there is no triggerTitle by default keep the modal open
      open: props.data.triggerTitle ? false : true,
    };
  }

  render() {
    const onOpenModal = () => {
      this.setState({ open: true });
    };

    const onCloseModal = () => {
      this.setState({ open: false });
    };
    const { data, componentsMap, site } = this.props;
    return (
      <div className={data.className}>
        {data.buttonImage && (
          <img
            className="card-img-top mx-auto w-100 "
            alt={data.buttonImage}
            src={data.buttonImage}
          />
        )}
        {data.buttonContent && (
          <div className="card-body p-2 mb-2">
            <h2 className="">{data.title}</h2>
            {parse(data.buttonContent)}{" "}
            <p className="p-2 center text-center">
              {data.triggerTitle && (
                <Button onClick={onOpenModal}>{data.triggerTitle}</Button>
              )}
            </p>
          </div>
        )}

        {data.triggerTitle && !data.buttonContent && (
          <Button onClick={onOpenModal}>{data.triggerTitle}</Button>
        )}
        <Modal
          open={this.state.open}
          //if there is no trigger title do not close this form untill submit
          onClose={data.triggerTitle && onCloseModal}
        >
          <h2 className="px-5">{data.title}</h2>
          <div className="content">
            {data.component
              ? data.component.map((item, idx) =>
                  Object.entries(item).map(function ([key, val]) {
                    const Comp = componentsMap[key];
                    return (
                      <Comp
                        data={val}
                        site={site}
                        key={idx}
                        clickFx={onCloseModal}
                      />
                    );
                  })
                )
              : data.content}
          </div>
        </Modal>
      </div>
    );
  }
}

export default SimpleModal;
